<template>
  <div class="">
    <div class="d-flex flex-column align-center mt-4">
      <v-icon large style="transform: rotate(-45deg)">mdi-key-outline</v-icon>
    </div>

    <div class="d-flex flex-column align-center mt-6">
      <div class="text-h6">Set new password</div>
      <div class="text-center">
        Your new password must be different to <br />
        previously used passwords.
      </div>
    </div>

    <v-form v-if="isLinkValid" lazy-validation ref="form" class="mt-10">
      <v-text-field
        v-model="form.password"
        @click:append="showPassword = !showPassword"
        :rules="[$rules.required, $rules.password]"
        :disabled="loading"
        :type="!showPassword ? 'password' : 'text'"
        placeholder="super secret password"
        prepend-inner-icon="mdi-lock-outline"
        :append-icon="showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
        class="rounded-lg"
        height="48px"
        outlined
      ></v-text-field>

      <v-text-field
        v-model="form.confirmPassword"
        @click:append="showConfirmPassword = !showConfirmPassword"
        :rules="[$rules.required, matchPassword]"
        :disabled="loading"
        :type="!showConfirmPassword ? 'password' : 'text'"
        placeholder="confirm super secret password"
        prepend-inner-icon="mdi-lock-outline"
        :append-icon="
          showConfirmPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
        "
        class="rounded-lg"
        height="48"
        outlined
      >
      </v-text-field>

      <v-btn
        @click="submit"
        :loading="loading"
        color="primary"
        class="rounded-lg font-weight-bold"
        height="48px"
        block
      >
        Reset Password
      </v-btn>
    </v-form>

    <div class="mt-10" v-else>
      <v-alert icon="mdi-alert-circle-outline" outlined type="error" prominent
        >Oops! Something went wrong. <br />The link has expired or is
        incorrect.</v-alert
      >

      <v-btn
        @click="navigateToResetPasswordRoute"
        color="primary"
        class="rounded-lg mt-4 font-weight-bold"
        height="48px"
        block
      >
        Resend New Link
      </v-btn>
    </div>

    <v-btn
      @click="navigateToLoginRoute"
      class="rounded-lg mt-4 font-weight-bold text-none"
      height="48px"
      block
      text
    >
      <v-icon left>mdi-arrow-left</v-icon>
      Back to log in
    </v-btn>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      loading: false,
      isLinkValid: false,

      form: {
        password: "",
        confirmPassword: "",
      },

      showPassword: false,
      showConfirmPassword: false,
    };
  },

  async mounted() {
    await this.checkResetPasswordLink();
  },

  methods: {
    ...mapActions({
      showSnackbar: "app/showSnackbar",
    }),

    matchPassword(value) {
      return value === this.form.password || "Passwords did not match";
    },

    navigateToResetPasswordRoute() {
      this.$router.push({ name: "resetPassword" });
    },

    navigateToResetPasswordDoneRoute() {
      this.$router.push({ name: "resetPasswordDone" });
    },

    navigateToLoginRoute() {
      this.$router.push({ name: "login" });
    },

    checkResetPasswordLink() {
      const data = {};
      data["uid"] = this.$route.params.uid;
      data["token"] = this.$route.params.token;

      const onSuccess = () => {
        this.isLinkValid = true;
      };

      const onFailure = () => {
        this.isLinkValid = false;
      };

      return this.$request(this.$keys.POST, this.$urls.auth.passwordCheckLink, {
        data,
        onSuccess,
        onFailure,
        isTokenRequired: false,
      });
    },

    submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        const onSuccess = (res) => {
          const resData = res.data;

          this.showSnackbar({
            text: resData.message,
            color: "success",
          });

          this.navigateToResetPasswordDoneRoute();
        };

        const onFinally = () => {
          this.loading = false;
        };

        const data = {};
        data["password"] = this.form.password;
        data["uid"] = this.$route.params.uid;
        data["token"] = this.$route.params.token;

        return this.$request(
          this.$keys.POST,
          this.$urls.auth.resetPasswordSubmit,
          {
            data: data,
            onSuccess,
            onFinally,
            isTokenRequired: false,
          }
        );
      }
    },
  },
};
</script>